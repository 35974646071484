'use client';

import { DefaultErrorMessage } from '~/components';

const GlobalError = ({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) => {
  return (
    <html>
      <body>
        <DefaultErrorMessage error={error} reset={reset} />
      </body>
    </html>
  );
};

export default GlobalError;
